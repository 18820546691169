.dateField {
  white-space: nowrap;
}

.specialOuter {
  height: 100%;
  width: 100%;
  padding: 2%;
}
.outerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerFlex {
  border: red solid 0px;
  width: 50vw;
  margin: auto;
}
.downloadBtns {
  margin: 5px;
  width: 100%;
}
.loginInner {
  padding: 10px;
}
.sr-only {
  display: none;
}
#readOnly {
  background-color: white;
}
.adminTan {
  background-color: #ffefd3;
  border: solid #144771 4px;
}
.myForm {
  border: solid #144771 1px;
}

.adminNavActiveLink {
  color: black !important;
}

.adminNavActiveLink::after {
  content: "";
  background-color: black;
  display: block;
  height: 4px;
  width: 100%;
}

.adminNavLink {
  color: black !important;
}
.calendar {
  width: auto;
}

.adminNavLink::after {
  content: "";
  background-color: transparent;
  display: block;
  height: 4px;
  width: 0;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.adminNavLink:hover::after {
  width: 100%;
  background-color: black;
}

.rescueStoryUpdateImg {
  max-height: 440px;
  max-width: 240px;
  border: 4px solid #144771;
  border: 4px solid #144771;
}
.adminBtns {
  padding: 10px;
}

#specialAdminNavLink {
  border: #144771 4px solid;
  border-radius: 20px;
}

#adminBreaker {
  background-color: black;
}
@media (max-width: 768px) {
  .innerFlex {
    width: 75vw;
  }
}
th,
td {
  text-align: center;
}
.adminSection {
  margin: 2%;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1800px) {
  .adminSection {
    margin: 10%;
  }
}
