* {
  font-family: Optima, sans-serif;
  /* border: 1px solid red; */
  /* font-family: arial; */
}
/* very important fixes a ton of issues on phone screen sizes with 
extra white space to the right!! */
html,
body {

  width: 100%;
  /* height: 100%; */
  margin: 0px;
  padding: 0px;
  /* overflow-x: hidden; */
}

html {
  font-size: 18px;
}
body {
  font-size: 100%;
}
.userHtml {
  background: #ffefd3;
  min-height: 100vh;
  height: 100%;
  position: relative;
}
p,
li,
button,
input,
a {
  font-size: 1em !important;
  /* color: blue !important; */
}
.form-select{
    font-size: 1em !important;
}
textarea.form-control{
    font-size: 1em !important;
}



h1 {
  font-size: 2.25em !important;
  /* color: green !important;; */
}
h2 {
  font-size: 2em !important;
  /* color: purple !important; */
}
h3 {
  /* color: orange !important; */
  font-size: 1.75em !important;
}
h4 {
  /* color: red !important; */
  font-size: 1.5em !important;
}

.leftAlignDiv {
  display: inline-block;
  text-align: left;
}

.dateField {
  width: 140px;
}
#contactOptions {
  text-align: left;
}

#minorText {
  font-size: 12 !important;
  color: #686868;
  margin-top: 0;
  padding-top: 0;
  border: black, solid, 2px;
}

.main {
  margin-top: 30px;
}

.box {
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: top;
  padding: 5px 10px 5px 10px;
  display: inline-block;
  border: #144771 solid 2px;
  border-radius: 15px;
}

.themeBlue {
  color: #144771;
}

.adoptableImg {
  max-height: 100vw;
  max-width: 100%;
  border-radius: 25px;
}

.dogContainer {
  margin: 2.5% auto;
  background-color: #fff9ee;
  border-top: 8px solid #144771;
  border-bottom: 8px solid #144771;
}

.dogImgContainer {
  padding: 3% 1.5% 3% 3%;
}
.dogTextContainer {
  padding: 3% 3% 3% 1.5%;
}
.adoptionFormBox {
  border-left: #144771 solid 5px;
  padding: 10px;
}

.themeBlue .form-control {
  background-color: #ffefd3;
  border: #c7c5c5 solid 1px;
  color: #144771;
}
.themeBlue .form-control:focus {
  background-color: #ffefd3;
  color: #144771;
  border: #144771 solid 1px;
  box-shadow: none;
}

.themeBlue .form-select {
  background-color: #ffefd3;
  color: #144771;
  border: #c7c5c5 solid 1px;
}
.themeBlue .form-select:focus {
  background-color: #ffefd3;
  color: #144771;
  border: #c7c5c5 solid 1px;
  box-shadow: none;
}

p {
  position: relative;
}

div.container-fluid {
  position: relative;
  z-index: 20;
}

.userNav {
  background-color: #144771;
  /* white-space: nowrap; */
}

#noPadding {
  padding: 0;
}

#extra3Padding {
  padding: 3%;
}
#extra3PaddingTop {
    padding: 3%;
  }

.homeNavLink {
  /* margin: 0 15px;
  padding: 0px; */
  color: #c7c5c5 !important;
  color: #f0f0f0 !important;
}

.homeNavLink::after {
  content: "";
  background-color: transparent;
  height: 4px;
  width: 0;
  display: block;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.homeNavLink:hover::after {
  width: 100%;
  background-color: #c7c5c5;
  background-color: #f0f0f0;
}

.homeNavActiveLink {
  margin: 0 15px;
  padding: 0px;
  color: #c7c5c5 !important;
  color: #f0f0f0 !important;
}
.homeNavActiveLink::after {
  content: "";
  background-color: #c7c5c5;
  background-color: #f0f0f0;
  height: 4px;
  width: 100%;
  display: block;
}

.beegDogFrame {
  width: 100%;
  height: 30vw;
  position: relative;
  overflow: hidden;
}
.beegDog {
  width: 100%;
  height: 100%;
  object-position: 0% 40%;
  object-fit: cover;
}

.beegDogText {
  position: absolute;
  top: 20%;
  left: 30px;
  background: rgba(20, 71, 113, 0.8);
  color: #ffefd3;
  padding: 5px 10px;
  border-radius: 20px;
}
.beegDogText h2,
.beegDogText a {
  -webkit-font-smoothing: antialiased;
}

#extra30MarginTB {
  margin: 3% auto;
}

.petCareCarouselPics {
  border-radius: 25px;

  height: 325px;
  /* height: 100%; */
  object-fit: cover;
  object-position: center;
}
.actualCarousel {
  border-radius: 25px;
  width: 500px;
}

.donatePics {
  max-height: 50px;
  display: inline-block;
  vertical-align: middle;
}

#paypalHeader {
  background-color: transparent;
  border: 0px;
  color: #144771;
  font-weight: 500;
}

.donateHeaders {
  text-decoration: none;
  color: #144771;
  display: inline-block;
  font-weight: bolder;
}
.donateHeaders:hover {
  color: #144771;
}
.noWrap {
  white-space: nowrap;
}

.donateHeaders::after {
  content: "";
  height: 4px;
  width: 0;
  background-color: transparent;
  display: block;
  margin: 5px 0;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.donateHeaders:hover::after {
  width: 100%;
  background-color: #144771;
}

.donateP {
  margin-top: -10px;
  font-size: large;
  text-align: center;
  margin-bottom: 30px;
}

.donateDiv {
  margin: auto;
  margin-top: 30px;

  width: 100%;
}
.stickyNav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}
.tan {
  background-color: #ffefd3;
}

.themeButton {
  background-color: #144771;
  border-color: #144771;
  color: #f0f0f0;
}
#coolBtn {
  background-color: #144771;
  border-color: #144771;
  color: #f0f0f0;
}

.logoDiv {
  padding-top: 2px;
}

.justBlueBox {
  height: 166px;
  background: #144771;
}

.customLink {
  color: #c7c5c5;
  color: #f0f0f0;
  text-decoration: none;
}
.customLink:hover {
  color: #c7c5c5;
  color: #f0f0f0;
  text-decoration: underline;
}

#topRight {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  padding-top: 5px;
  padding-right: 10px;
  color: transparent;
  transition: color 0.3s ease;
}
#topRight:hover {
  color: #ffefd3;
}

#topRightContainer {
  position: relative;
}

.verticalDogPic {
  display: flex;
  justify-content: center;
  align-items: center;
}
.verticalDogPic img {
  width: 75%;
  border-radius: 25px;
}

#noMargin {
  margin: 0;
}
.underlineHeading {
  text-align: center;
  font-weight: bolder;
  text-decoration: underline;
  padding: 0;
}
#noMarginExtraPadding {
  margin: 0;
  padding: 3%;
}

.fullPage {
  position: relative;
}

#picB {
  max-height: 400px;
  /* max-height: 50vh; */
  max-width: 100%;
  border-radius: 25px;
}

.rescueQuoteDiv {
  width: 75%;
  margin: auto;
}

.footerRight {
  text-align: right;
}
.footerDiv {
  /* border: green solid 0px; */
  padding: 20px;
  background-color: #144771;
  color: #f0f0f0;
  width: 100%;
  /* overflow: hidden; */

}

.fullPic {
  max-width: 100%;
  max-height: 400px;
  border-radius: 25px;
  padding: 0px 0px;
}
.diaperPic{
    max-width: 100%;
    border-radius: 25px;
    margin-bottom: 20px;
}

.pushDown {
  height: 40px;
}
.donatePushDown {
  height: 40px;
}

.epicFlex {
  align-items: center;
  height: 100%;
}

.squishTxt {
  padding: 0 20%;
}
.squishTxtMini {
  padding: 0 10%;
}
/** @ media this .**/
#logoPic {
  height: 160px;
}
.homeColImgDiv {
  border: black solid 0px;
  width: 100%;
  height: 300px;
}

.homeColImgDiv img {
  display: block;
  margin: auto;
  border: black solid 5px;
}

.followUsDiv {
  margin-bottom: 40px;
}

.myCoolPic {
  max-width: 75%;
}
hr {
  background: #144771;
  height: 4px !important;
  opacity: 1;
  margin-left: -15px;
  margin-right: -15px;
}

.smushDiv {
  border: green solid 0px;
  width: 100%;
  margin: auto;
}

#thankYouDog {
  width: 50%;
  border-radius: 25px;
}
#fullContainer{
    width:100%;
}
#specialShrinkh2{
    font-size: 1.60em !important; 
       }
       #specialShrinkh4{
        font-size: 1.35em !important; 
           }

/* X-Small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    #specialShrinkh2{
        font-size: 1.75em !important; 
           }
           #specialShrinkh4{
            font-size: 1.5em !important; 
               }

    .petCareCarouselPics {
        height: 400px;
      }
}

/* Medium devices (tablets, 768px and up)/ addA*/
@media (min-width: 768px) {
    .adoptableImg {
        max-height: 50vw;
      }
    #specialShrinkh2{
        font-size: 2em !important; 
           }

  .homeNavLink {
    margin: 0 4px;
    padding: 0px;
  }
  .userNavClass {
    position: absolute;
    bottom: 0;
  }

  .userNavClass.left {
    right: 93px;
  }
  .userNavClass.right {
    left: 93px;
  }
  .userNavRow {
    border: 0px;
    margin-right: 0;
    margin-left: 0;
  }
  #hideOnBig {
    display: none !important;
  }
  .footColL {
    text-align: left;
  }
  .footColR {
    text-align: right;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .homeNavLink {
        margin: 0 15px;
        padding: 0px;
      }
    .specialPadTopBottom{
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    .diaperPic{
        max-height: 80%;
    }
  .fullPic {
    max-width: 100%;
    max-height: 25vw;
    border-radius: 25px;
    padding: 0px 0px;
  }
  #picB {
    max-height: 25vw;
    /* max-height: 50vh; */
    max-width: 100%;
    border-radius: 25px;
  }
  body {
    font-size: 100%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body {
    font-size: 100%;
  }
  /* .fullPic {
    max-height: 20vh;
  }
  #picB {
    max-height: 20vh;
  } */
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1500px) {
  #logoPic {
    height: 200px;
  }
  body {
    font-size: 120%;
  }
  hr {
    height: 6px !important;
  }
  .fullPic {
    max-height: 20vw;
  }
  #picB {
    max-height: 20vw;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 2500px) {
  #logoPic {
    height: 250px;
  }
  hr {
    height: 10px !important;
  }
  body {
    font-size: 150%;
  }
  .aboutDogImg,
  .aboutDogText {
    width: 100%;
  }
  .petCareCarouselPics {
    height: 600px;
  }
  .actualCarousel {
    width: 85%;
  }

  .container {
    width: 80%;
    max-width: none;
    /* padding: 0; */
  }
  .fullPic, .diaperPic {
    max-height: 20vw;
  }
  #picB {
    max-height: 20vw;
  }
}

@media (min-width: 3000px) {
  #logoPic {
    height: 300px;
  }
  .petCareCarouselPics {
    height: 700px;
  }
  .actualCarousel {
    width: 85%;
  }
  body {
    font-size: 200%;
  }
  .container {
    width: 80%;
    max-width: none;
    padding: 0;
  }
}

@media (min-width: 3500px) {
  #logoPic {
    height: 350px;
  }
  .petCareCarouselPics {
    height: 700px;
  }
  .actualCarousel {
    width: 75%;
  }
  body {
    font-size: 220%;
  }
}
@media (min-width: 4000px) {
  .petCareCarouselPics {
    height: 800px;
  }
  .actualCarousel {
    width: 75%;
  }
  /* .petCareCarouselPics haha a {
        height: 1200px;
      } */
}
@media (min-width: 4300px) {
  #logoPic {
    height: 400px;
  }
  body {
    font-size: 250%;
  }
  hr {
    height: 20px !important;
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
@media (min-width: 4500px) {
  .fullPic, .diaperPic {
    max-height: 18vw;
  }
  #picB {
    max-height: 18vw;
  }
  .petCareCarouselPics {
    height: 900px;
  }
  .actualCarousel {
    width: 75%;
  }
}
@media (min-width: 5000px) {
  .petCareCarouselPics {
    height: 900px;
  }
  /* .actualCarousel {
    width: 55%;
  } */
}



/* XX-Large devices (larger desktops) */
/* No media query since the xxl breakpoint has no upper bound on its width */
/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {


  #thankYouDog {
    width: 100%;
    border-radius: 25px;
  }

  .actualCarousel {
    border-radius: 25px;
    width: 100%;
  }

  .donateDiv .container {
    padding-left: 0;
    padding-right: 0;
    /* border: red solid 2px; */
  }
  .dogImgContainer {
    padding: 3%;
  }
  .dogTextContainer {
    padding: 3%;
  }
  .centerSmall {
    justify-content: center;
  }
  .smallTxtCenter {
    text-align: center;
  }
  .beegDogText {
    top: 0;
    left: 0;
    right: 0;

    text-align: center;
    border-radius: 0;
  }
  .beegDogFrame {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
  }
  .beegDog {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: 50% 0;
  }

  .squishTxt {
    padding: 0 10%;
  }
  .squishTxtMini {
    padding: 0 5%;
  }
  #hideOnPhone {
    display: none !important;
  }
  .footColL,
  .footColR {
    text-align: center;
  }
  .followUsDiv {
    margin-bottom: 80px;
  }
  .donatePushDown {
    height: 100px;
  }
  .petCarePushDown {
    height: 40px;
  }

  .pushDown {
    height: 80px;
  }
  .fullPic {
    /* max-width: 100%; */
    margin-bottom: 20px;
  }
  #picB {
    margin-bottom: 20px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .p-5 {
    padding: 0 5px 3rem 5px !important;
  }
  .actualCarousel {
    margin-bottom: 20px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .petCareNotice {
    margin-top: 2rem;
  }
}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

.paddingTopBottom {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.pb-1{
    padding-bottom: 1rem !important;

}


